import { Progress } from "@ark-ui/react";
import {
  AromaLoaderCircleVariantProps,
  aromaLoaderCircle,
} from "@zenchef/styled-system/recipes";
import React, { ElementRef, forwardRef, useMemo } from "react";

export interface LoaderCircleProps
  extends AromaLoaderCircleVariantProps,
    Omit<Progress.RootProps, "style">,
    Omit<React.HTMLAttributes<HTMLDivElement>, "style"> {
  description?: string;
  testId?: string;
}

const useCircleDots = (
  dotCount: number,
  angleStep: number,
  radius: number,
  circleSize: number,
) => {
  return useMemo(() => {
    return Array.from({ length: dotCount }, (_, i) => {
      const angle = i * angleStep;
      const radian = (angle * Math.PI) / 180;
      const x = radius * Math.cos(radian) + circleSize / 2;
      const y = radius * Math.sin(radian) + circleSize / 2;
      const opacity = (0.9 * i) / (dotCount - 1);

      return { x, y, opacity };
    });
  }, [dotCount, angleStep, radius, circleSize]);
};

/**
 * LoaderCircle
 * @returns Styled circle loader implemented with ArkUI.
 */
const LoaderCircle = forwardRef<ElementRef<"div">, LoaderCircleProps>(
  ({ testId, description, ...propsWithVariants }, ref) => {
    const [variants, props] = aromaLoaderCircle.splitVariantProps(
      propsWithVariants as AromaLoaderCircleVariantProps,
    );
    const classes = aromaLoaderCircle(variants);

    const circleParams = {
      small: 20,
      medium: 32,
      large: 48,
    };

    const circleSize = circleParams[variants.size as keyof typeof circleParams];
    const dotCount = 15;
    const dotRadius = circleSize / dotCount;
    const radius = (circleSize - 2 - dotRadius) / 2;
    const angleStep = 360 / dotCount;

    const dots = useCircleDots(dotCount, angleStep, radius, circleSize);

    return (
      <Progress.Root
        className={classes.root}
        ref={ref}
        data-testid={testId}
        {...props}
      >
        <Progress.Circle className={classes.circle}>
          {dots.map((dot, index) => (
            <circle
              className={classes.dot}
              key={index}
              cx={dot.x}
              cy={dot.y}
              r={dotRadius}
              style={{ opacity: dot.opacity }}
            />
          ))}
        </Progress.Circle>
        {description && (
          <Progress.Label className={classes.label}>
            {description}
          </Progress.Label>
        )}
      </Progress.Root>
    );
  },
);

export default LoaderCircle;
