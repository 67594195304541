import { styled } from "@zenchef/styled-system/jsx";

export const RichText = styled("div", {
  base: {
    width: "100%",
  },
  variants: {
    size: {
      xs: {
        textStyle: "paragraph.xs.regular",
        "& a": {
          textStyle: "link.xs",
        },
        "& strong": {
          textStyle: "paragraph.xs.bold",
        },
      },
      s: {
        textStyle: "paragraph.s.regular",
        "& a": {
          textStyle: "link.s",
        },
        "& strong": {
          textStyle: "paragraph.s.bold",
        },
      },
      m: {
        textStyle: "paragraph.m.regular",
        "& a": {
          textStyle: "link.m",
        },
        "& strong": {
          textStyle: "paragraph.m.bold",
        },
      },
      l: {
        textStyle: "paragraph.l.regular",
        "& a": {
          textStyle: "link.l",
        },
        "& strong": {
          textStyle: "paragraph.l.bold",
        },
      },
    },
  },
  defaultVariants: {
    size: "m",
  },
});
