import {
  aromaDropdown,
  AromaDropdownVariantProps,
} from "@zenchef/styled-system/recipes";
import {
  ElementRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  RefAttributes,
  useMemo,
} from "react";
import {
  Select,
  Portal,
  SelectValueTextProps,
  SelectRootBaseProps,
  SelectItemBaseProps,
  createListCollection,
} from "@ark-ui/react";
import Icon from "../Icon/Icon";
import { IconName } from "@zenchef/icomoon";
import { Text } from "../typography/Text";
import { FieldProps } from "../../utils/types";
import { cx } from "@zenchef/styled-system/css";

export interface DropdownItem<T, U> {
  label: U;
  value: T;
  iconName?: IconName;
  disabled?: boolean;
}

interface DropdownOptionProps<T, U>
  extends AromaDropdownVariantProps,
    SelectItemBaseProps {
  item: DropdownItem<T, U>;
}

const DropdownOption = forwardRef(function InternalDropdownOption<
  T,
  U extends ReactNode = string,
>(
  { item, ...propsWithVariants }: DropdownOptionProps<T, U>,
  ref: ForwardedRef<ElementRef<"div">>,
) {
  const [variants] = aromaDropdown.splitVariantProps({
    ...propsWithVariants,
  });
  const classes = aromaDropdown(variants);

  return (
    <Select.Item
      ref={ref}
      item={item}
      className={classes.item}
      aria-disabled={item.disabled ? "true" : undefined}
    >
      <Select.ItemText className={classes.itemText}>
        {typeof item.label === "string" ? (
          <>
            {item.iconName ? <Icon name={item.iconName} /> : null}
            <Text textStyle="paragraph.m.regular">{item.label}</Text>
          </>
        ) : (
          item.label
        )}
      </Select.ItemText>

      <Select.ItemIndicator className={classes.itemIndicator}>
        <Icon name="check-01" />
      </Select.ItemIndicator>
    </Select.Item>
  );
});

export interface DropdownProps<T extends string, U extends ReactNode>
  extends AromaDropdownVariantProps,
    Pick<
      SelectRootBaseProps<DropdownItem<T, U>>,
      | "closeOnSelect"
      | "defaultOpen"
      | "disabled"
      | "form"
      | "name"
      | "positioning"
      | "required"
      | "readOnly"
    >,
    Pick<SelectValueTextProps, "placeholder">,
    FieldProps {
  items: DropdownItem<T, U>[];
  iconName?: IconName;
  iconOnly?: boolean;
  prefix?: string;
  suffix?: string;
  value?: T;
  defaultValue?: T;
  maxContentHeight?: number;
  contentWidth?: number;
  /**
   * Z-index of the dropdown options
   * @default "1000"
   */
  zIndex?: string;
  onChange?: (value: T) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

/**
 * Basic usage:
 * <Dropdown items={[...]} value={value} onChange={setValue} />
 */
const Dropdown = forwardRef(function InternalDropdown<
  T extends string,
  U extends ReactNode,
>(
  {
    items,
    value,
    defaultValue,
    onChange,
    className,
    iconName,
    prefix,
    suffix,
    positioning,
    maxContentHeight = 280,
    contentWidth,
    zIndex = "1000",
    ...propsWithVariants
  }: DropdownProps<T, U>,
  ref: ForwardedRef<ElementRef<"div">>,
) {
  const [variants, props] = aromaDropdown.splitVariantProps(propsWithVariants);
  const classes = aromaDropdown(variants);

  const { required, placeholder } = props;

  // trick to have working ReactNode as labels
  const collection = items.every(({ label }) => typeof label === "string")
    ? createListCollection({ items })
    : createListCollection({
        items: [],
      });

  const selectedValue = useMemo(
    () => items.find((item) => item.value === value),
    [value],
  );

  return (
    <Select.Root
      ref={ref}
      collection={collection}
      value={value ? [value] : undefined}
      defaultValue={defaultValue ? [defaultValue] : undefined}
      onValueChange={
        onChange ? ({ value }) => onChange(value[0] as T) : undefined
      }
      positioning={{ placement: "bottom-end", ...positioning }}
      className={cx(classes.root, className)}
      {...props}
    >
      <Select.Control>
        <Select.Trigger className={classes.trigger}>
          {prefix ? <Text textStyle="title.xs">{prefix}</Text> : null}
          {iconName ? <Icon name={iconName} /> : null}
          {propsWithVariants?.iconOnly ? (
            <>{suffix ? <Text textStyle="title.xs">{suffix}</Text> : null}</>
          ) : (
            <>
              <Select.ValueText
                className={classes.valueText}
                placeholder={placeholder}
              >
                {selectedValue?.label}
              </Select.ValueText>
              {suffix ? <Text textStyle="title.xs">{suffix}</Text> : null}
              <Select.Indicator className={classes.indicator}>
                <Select.Context>
                  {({ open }) => (
                    <Icon name={open ? "chevron-up" : "chevron-down"} />
                  )}
                </Select.Context>
              </Select.Indicator>
            </>
          )}
          {required ? <Icon name="asterisk" aria-required="true" /> : null}
        </Select.Trigger>
      </Select.Control>
      <Portal>
        <Select.Positioner style={{ zIndex }}>
          <Select.Content
            className={classes.content}
            style={{
              ...(maxContentHeight ? { maxHeight: maxContentHeight } : {}),
              ...(!positioning?.sameWidth && contentWidth
                ? { width: contentWidth }
                : {}),
            }}
          >
            {items.map((item) => (
              <DropdownOption key={item.value} item={item} />
            ))}
          </Select.Content>
        </Select.Positioner>
      </Portal>
      <Select.HiddenSelect />
    </Select.Root>
  );
  // trick to have working genericity
}) as <T extends string, U extends ReactNode>(
  props: DropdownProps<T, U> & RefAttributes<ElementRef<"div">>,
) => ReactElement;

export default Dropdown;
