import { IconName } from "@zenchef/icomoon";
import { HTMLStyledProps, styled } from "@zenchef/styled-system/jsx";
import {
  aromaAlert,
  AromaAlertVariant,
  AromaAlertVariantProps,
} from "@zenchef/styled-system/recipes";
import { ElementRef, forwardRef } from "react";
import Icon from "../Icon/Icon";
import { cx } from "@zenchef/styled-system/css";

export interface AlertProps
  extends AromaAlertVariantProps,
    Omit<HTMLStyledProps<"div">, "title"> {
  iconName?: IconName;
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  dangerouslySetInnerHTML?: { __html: string };
  children?: React.ReactNode;
}

const ALERT_ICON = {
  neutral: undefined,
  information: "info-circle",
  warning: "alert-circle",
  error: "alert-triangle",
  success: "check-01-circle",
} as const satisfies Record<
  AromaAlertVariant["function"],
  IconName | undefined
>;

/**
 * Alert
 * <Alert function="success" title="Success" description="This is a success alert" />
 * @property iconName - to be used with `function="neutral"`
 * @property reversed - to be used within whitelabel theme, when the alert is displayed on a brand background
 */
const Alert = forwardRef<ElementRef<"div">, AlertProps>(
  (
    {
      title,
      description,
      children,
      dangerouslySetInnerHTML,
      function: functionFromProps,
      iconName: iconNameFromProps,
      className,
      ...props
    },
    ref,
  ) => {
    const alertType =
      typeof functionFromProps === "string" ? functionFromProps : "neutral";
    const [otherVariantProps, restProps] = aromaAlert.splitVariantProps(props);
    const iconName = iconNameFromProps ?? ALERT_ICON[alertType] ?? null;
    const classes = aromaAlert({
      function: functionFromProps,
      ...otherVariantProps,
    });
    return (
      <styled.div
        className={cx(classes.root, className)}
        {...restProps}
        ref={ref}
      >
        {iconName && <Icon name={iconName} className={classes.icon} />}
        <div className={classes.content}>
          {title !== undefined || description !== undefined ? (
            <>
              {title && <div className={classes.title}>{title}</div>}
              {description && (
                <div className={classes.description}>{description}</div>
              )}
            </>
          ) : dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          ) : (
            children
          )}
        </div>
      </styled.div>
    );
  },
);

Alert.displayName = "Alert";

export default Alert;
