import { ark } from "@ark-ui/react";
import { aromaButton } from "@zenchef/styled-system/recipes";
import { styled } from "@zenchef/styled-system/jsx";
import withTestId from "../../utils/withTestId";
import { ComponentProps } from "@zenchef/styled-system/types";

const Button = styled(withTestId("btn")(ark.button), aromaButton);

export type ButtonProps = ComponentProps<typeof Button>;

export default Button;
