export type TestIdType =
  | "btn"
  | "input"
  | "checkbox"
  | "radio"
  | "dropdown"
  | "textarea"
  | "image"
  | "link"
  | "table"
  | "list"
  | "modal"
  | "form"
  | "toast"
  | "label"
  | "paragraph"
  | "header"
  | "footer"
  | "nav"
  | "switch"
  | "section"
  | "field"
  | "alert";

interface CreateTestIdParameters {
  testId?: string;
  suffix?: string;
  type: TestIdType;
}

export interface TestingAttributes {
  testId?: string;
  suffix?: string;
}

export const addSuffix = ({
  testId,
  suffix,
}: Omit<CreateTestIdParameters, "type">) => {
  if (suffix && testId) {
    return `${testId}-${suffix}`;
  }
  return testId;
};

const createTestId = ({ testId, suffix, type }: CreateTestIdParameters) => {
  return addSuffix({ testId: addSuffix({ testId, suffix }), suffix: type });
};

export default createTestId;
