import { styled } from "@zenchef/styled-system/jsx";
import { HTMLStyledProps } from "@zenchef/styled-system/types";
import { IconName } from "@zenchef/icomoon";
import { ElementRef, forwardRef } from "react";
import { cx } from "@zenchef/styled-system/css";

export interface IconProps extends HTMLStyledProps<"i"> {
  name?: IconName;
}

const Icon = forwardRef<ElementRef<"i">, IconProps>(
  ({ name = "placeholder-circle", className, ...props }, ref) => {
    return (
      <styled.i
        ref={ref}
        flexShrink={0}
        className={cx(`aromaticon-${name}`, className)}
        {...props}
      />
    );
  },
);

export default Icon;
