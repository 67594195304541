import {
  ComponentType,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  Ref,
  RefAttributes,
} from "react";

import createTestId, { TestIdType, TestingAttributes } from "./createTestId";

export default function withTestId(testIdType: TestIdType) {
  return function withForwardComponent<R, P extends object>(
    Component: ComponentType<P & { ref?: Ref<R> }>,
  ): ForwardRefExoticComponent<
    PropsWithoutRef<P & TestingAttributes> & RefAttributes<R>
  > {
    return forwardRef<R, P & TestingAttributes>(
      ({ testId, suffix, ...props }, ref) => {
        return (
          <Component
            ref={ref}
            data-testid={createTestId({ testId, type: testIdType, suffix })}
            {...(props as P)}
          />
        );
      },
    );
  };
}
